/* eslint-disable */
import PropTypes from "prop-types";
import SuiAvatar from "components/SuiAvatar";
import { useState, useEffect } from "react";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiSnackbar from "components/SuiSnackbar";
import { Card, Icon } from "@mui/material";
// import KbankLogo from "assets/bankimg/004.png";
// import TrueLogo from "assets/bankimg/999.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SuiButton from "components/SuiButton";
import { formatter } from "utils/validations";
import billSlip from "assets/png/bill_slip.png";
import pendingDeposit from "assets/gif/pending_deposit.gif";
import SuiBadge from "components/SuiBadge";
// import SuiButton from "components/SuiButton";

const API_SERVER = "https://www.29agentxapi.com/";

function DepositInfo({ amount, defaultBank, expiredAt, onCancleDeposit }) {
  const [infoSB, setInfoSB] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [time, setTime] = useState(expiredAt);

  const renderInfoSB = (
    <SuiSnackbar
      icon="copy"
      type="info"
      title={`${defaultBank?.bank_name}: ${defaultBank?.agent_bank_number}`}
      open={infoSB}
      onClose={() => setInfoSB(false)}
      close={() => setInfoSB(false)}
    />
  );

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  useEffect(() => {
    let timer = setInterval(() => {
      setTime((time) => {
        if (time === 0) {
          console.log("DepositInfo-timeout");
          onCancleDeposit();
          clearInterval(timer);
          return 0;
        } else return time - 1;
      });
    }, 1000);
  }, []);

  return (
    <SuiBox>
      <SuiBox display="flex" justifyContent="center">
        <SuiBox
          component="img"
          src={billSlip}
          alt="billSlip"
          width="90%"
          // zIndex={1}
          position="absolute"
        />
      </SuiBox>

      <SuiBox textAlign="center">
        <SuiBox display="flex" flexDirection="column" alignItems="center">
          <SuiBox component="img" src={pendingDeposit} alt="billSlip" width="20%" mt={3} />
          <SuiTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textColor="secondary"
          >
            รอดำเนินการ
          </SuiTypography>
          {time > 0 && (
            <SuiBox>
              <SuiBadge
                variant="contained"
                color="warning"
                badgeContent={
                  <p>
                    {`${Math.floor(time / 60)}`.padStart(2, 0)}:{`${time % 60}`.padStart(2, 0)}
                  </p>
                }
                circular
                container
              />
            </SuiBox>
          )}
        </SuiBox>
      </SuiBox>
      <SuiBox display="flex" flexDirection="column" textAlign="center" p={2}>
        <SuiBox display="flex" justifyContent="center">
          <SuiAvatar
            src={`${API_SERVER}images/banks/${defaultBank?.bank_code}.png`}
            alt={defaultBank.bank_name}
            size="sm"
            // variant="rounded"
          />
        </SuiBox>
        <SuiTypography mt={1} component="span" variant="caption" fontWeight="bold" textColor="dark">
          {defaultBank.bank_name}
        </SuiTypography>
        <SuiTypography mt={1} component="span" variant="caption" fontWeight="bold" textColor="dark">
          {defaultBank.agent_bank_name}
        </SuiTypography>
        <SuiTypography mt={1} component="span" variant="body2" fontWeight="bold" textColor="dark">
          {defaultBank.agent_bank_number}
        </SuiTypography>

        <SuiBox mt={1} display="flex" justifyContent="center">
          <SuiBox
            zIndex={99}
            position="absolute"
            sx={{
              cursor: "pointer",
            }}
          >
            <CopyToClipboard text={defaultBank.agent_bank_number} onCopy={() => setInfoSB(true)}>
              <SuiBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ cursor: "pointer" }}
              >
                <Icon sx={{ color: "#8392ab" }}>copy</Icon>
                <SuiTypography
                  component="span"
                  variant="caption"
                  fontWeight="bold"
                  textColor="secondary"
                >
                  คัดลอก
                </SuiTypography>
              </SuiBox>
            </CopyToClipboard>
          </SuiBox>
        </SuiBox>
      </SuiBox>
      {renderInfoSB}
      <SuiBox mt={2}>
        <SuiTypography variant="h2" fontWeight="bold" textAlign="center" textColor="success">
          {formatter.format(amount).toString()}
        </SuiTypography>
      </SuiBox>
      {/* <SuiBox display="flex" justifyContent="center">
        <SuiBox textAlign="center" width="80%">
          {defaultBank?.bank_code === "999" ? (
            <SuiTypography component="label" variant="caption" textColor="error">
              **โอนเงินแล้วกรุณาแจ้งสลิปทางไลน์ทุกครั้ง
            </SuiTypography>
          ) : (
            <SuiTypography component="label" variant="caption" textColor="secondary">
              **กรุณาตรวจสอบยอดเงินและทศนิยมก่อนโอน
              เพื่อความรวดเร็วในการทำรายการกรุณาโอนตามยอดที่แสดงนี้
            </SuiTypography>
          )}
        </SuiBox>
      </SuiBox> */}
      {/* <SuiBox display="flex" justifyContent="center">
        <SuiBox textAlign="center" width="80%">
          <Card>
            <SuiBox p={1} backgroundColor="warning" display="flex" flexDirection="column">
              <SuiTypography component="label" variant="caption" textColor="dark">
                กรุณาใช้บัญชีที่ท่านได้ทำการสมัครไว้ในระบบ
              </SuiTypography>
              <SuiTypography component="label" variant="caption" textColor="dark">
                ธนาคาร: {user?.bank_name}
              </SuiTypography>
              <SuiTypography component="label" variant="caption" textColor="dark">
                เลขที่บัญชี: {user?.member_bank_number}
              </SuiTypography>
            </SuiBox>
          </Card>
        </SuiBox>
      </SuiBox> */}
      <SuiBox mt={3}>
        <SuiButton
          variant="outlined"
          buttonColor="warning"
          size="small"
          circular
          onClick={() => onCancleDeposit()}
        >
          {`แก้ไข หรือ ยกเลิก`}
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

DepositInfo.defaultProps = {
  amount: "",
  defaultBank: {},
};

DepositInfo.propTypes = {
  amount: PropTypes.string,
  defaultBank: PropTypes.shape,
};

export default DepositInfo;
