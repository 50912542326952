/* eslint-disable no-console */
/**
=========================================================
* Soft UI Dashboard PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-material-ui
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import { Icon, IconButton } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function SetPassword({ onSetPassword }) {
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState();
  const [isShow, setShowPassword] = useState(false);
  const [isShowConfirm, setShowConfirm] = useState(false);

  function handleCheckPassword() {
    if (!password) {
      setError("รหัสผ่านห้ามเว้นว่าง");
      return;
    }
    if (!confirmPassword) {
      setError("กรุณากรอกรหัสผ่านที่ตรงกัน");
      return;
    }
    if (password !== confirmPassword) {
      setError("รหัสผ่านไม่ตรงกัน");
      return;
    }
    onSetPassword(password);
  }

  return (
    <SuiBox>
      <SuiBox component="form" role="form">
        <SuiBox mb={2}>
          <SuiTypography component="label" variant="caption" textColor="white">
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="white">
              ตั้งรหัสผ่าน *
            </SuiTypography>
            [ เฉพาะตัวอักษรภาษาอังกฤษและหรือตัวเลข ไม่เกิน 8 ตัว ]
          </SuiTypography>

          <SuiBox display="flex">
            <SuiInput
              type={isShow ? "text" : "password"}
              onChange={(event) => {
                setPassword(event.target.value);
                setError(undefined);
              }}
              placeholder="รหัสผ่าน"
              inputProps={{ maxLength: 8 }}
              size="small"
            />
            <IconButton
              sx={{ ml: 1 }}
              size="medium"
              color="inherit"
              onClick={() => setShowPassword(!isShow)}
            >
              <Icon className="text-white">
                {isShow ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </Icon>
            </IconButton>
          </SuiBox>

          <SuiBox sx={{ mt: 1 }}>
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="white">
              ยืนยันรหัสผ่าน *
            </SuiTypography>
            <SuiBox display="flex">
              <SuiInput
                type={isShowConfirm ? "text" : "password"}
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                  setError(undefined);
                }}
                placeholder="ยืนยันรหัสผ่าน"
                inputProps={{ maxLength: 8 }}
                size="small"
              />
              <IconButton
                sx={{ ml: 1 }}
                size="medium"
                color="inherit"
                onClick={() => setShowConfirm(!isShowConfirm)}
              >
                <Icon className="text-white">
                  {isShowConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </Icon>
              </IconButton>
            </SuiBox>
          </SuiBox>
        </SuiBox>

        <SuiBox mt={2} mb={2} textAlign="center">
          <h6
            style={{
              fontSize: ".7em",
              color: "red",
              textAlign: "center",
              fontWeight: 400,
              transition: ".2s all",
            }}
          >
            {error}
          </h6>
        </SuiBox>
        <SuiBox mt={4} mb={1} display="flex" justifyContent="center">
          <SuiButton
            onClick={() => handleCheckPassword()}
            variant="gradient"
            buttonColor="white"
            size="small"
            fullWidth
            circular
          >
            ยืนยัน
          </SuiButton>
        </SuiBox>
      </SuiBox>
    </SuiBox>
  );
}

SetPassword.defaultProps = {
  onSetPassword: () => {},
};

// Typechecking props of the SuiAlert
SetPassword.propTypes = {
  onSetPassword: PropTypes.func,
};

export default SetPassword;
