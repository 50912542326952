/* eslint-disable */
import { useState, useEffect } from "react";

// react-router-dom components
import { useHistory, Link } from "react-router-dom";
import breakpoints from "assets/theme/base/breakpoints";

// @mui material components
import Card from "@mui/material/Card";
import { Grid, IconButton, Icon, InputAdornment } from "@mui/material";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import Separator from "layouts/authentication/components/Separator";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PageLayout from "examples/LayoutContainers/PageLayout";
import ForgotPasswordPopup from "layouts/authentication/forgotPassword/forgotPasswordPopup";
import bf from "assets/jpg/bf.jpg";
import logonavbar from "assets/png/logonavbar.png";
import bflable from "assets/png/bflabel.png";
import ufalabel from "assets/png/ufalabel.png";
// import huaylabel from "assets/png/huaylabel.png";
import { CheckBox } from "@mui/icons-material";
import { useAuth } from "../../../../auth-context/auth.context";

function Basic() {
  const history = useHistory();
  // const { setUser } = useAuth();
  const { login } = useAuth();
  // const { setAccessToken } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [isShow, setShowPassword] = useState(false);
  const [isForgotPassword, setForgotPassword] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [buttonText, setButtonText] = useState("เข้าสู่ระบบ (Login)");
  const localUser = localStorage.getItem("user");
  const user = typeof localUser === "string" ? JSON.parse(localUser) : localUser;
  console.log("login-user", user);

  useEffect(() => {
    if (user) {
      console.log("user", user);
      history.push("/dashboard");
    }
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  }, []);

  const handleLogin = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      // return history.push("/dashboard");
    }
    if (username === "") {
      setError("You must enter your username.");
      return;
    }
    if (password === "") {
      setError("You must enter your password");
      return;
    }
    setButtonText("กำลังเข้าสู่ระบบ (Logingin)");
    console.log("username", username);
    console.log("password", password);
    const loginData = { username, password };
    try {
      const response = await login(loginData);
      console.log("singin-response", response);
      if (response?.data?.status !== "success") {
        setButtonText("เข้าสู่ระบบ (Login)");
        setError(response?.data?.message);
        return;
      }
      history.push("/dashboard");
      window.location.reload();
    } catch (err) {
      console.log(err);
      setButtonText("เข้าสู่ระบบ (Login)");
      setError("There has been an error.");
    }
  };

  return (
    <PageLayout>
      {isForgotPassword && <ForgotPasswordPopup onClose={() => setForgotPassword(false)} />}
      <SuiBox display="flex" height="100vh">
        <Grid container spacing={1}>
          {!mobileView && <Grid item xs={12} lg={4} />}
          <Grid item xs={12} lg={4}>
            <SuiBox
              display="flex"
              flex={1}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <SuiBox
                flex={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <SuiBox
                  mt={3}
                  component="img"
                  src={logonavbar}
                  alt="message-image"
                  borderRadius="lg"
                  boxShadow="md"
                  width="50%"
                  display="inherit"
                />
                <SuiBox
                  mt={2}
                  display="flex"
                  justifyContent="center"
                  width={mobileView ? "100%" : "50%"}
                >
                  <SuiBox
                    component="img"
                    src={ufalabel}
                    alt="message-image"
                    borderRadius="lg"
                    boxShadow="md"
                    width="30%"
                    display="inherit"
                  />
                  <SuiBox
                    ml={3}
                    component="img"
                    src={bflable}
                    alt="message-image"
                    borderRadius="lg"
                    boxShadow="md"
                    width="30%"
                    display="inherit"
                  />
                </SuiBox>
              </SuiBox>
              <SuiBox display="flex" flex={2} width="100%" justifyContent="center" p={2}>
                <Card
                  style={{
                    width: "100%",
                  }}
                >
                  <SuiBox display="flex" flexDirection="column" width="100%">
                    <SuiBox py={1} textAlign="center">
                      <SuiTypography component="label" variant="body2" textColor="dark">
                        {user && user.token ? "คุณได้เข้าสู่ระบบแล้ว" : "เข้าสู่ระบบ"}
                      </SuiTypography>
                    </SuiBox>

                    <SuiBox px={3}>
                      <SuiBox component="form" role="form">
                        {user && user.token ? null : (
                          <>
                            <SuiBox mb={2}>
                              <SuiInput
                                defaultValue={username}
                                onChange={(event) => {
                                  setUsername(event.target.value);
                                  setError(undefined);
                                }}
                                type="text"
                                placeholder="ยูสเซอร์หรือเบอร์โทรศัพท์"
                              />
                            </SuiBox>

                            <SuiBox>
                              <SuiBox display="flex">
                                <SuiInput
                                  defaultValue={password}
                                  onChange={(event) => {
                                    setPassword(event.target.value);
                                    setError(undefined);
                                  }}
                                  type={isShow ? "text" : "password"}
                                  placeholder="รหัสผ่าน"
                                />
                              </SuiBox>
                            </SuiBox>
                            <SuiBox>
                              <IconButton
                                size="medium"
                                color="inherit"
                                onClick={() => setShowPassword(!isShow)}
                              >
                                <Icon className="text-dark">
                                  {isShow ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </Icon>
                              </IconButton>

                              <SuiTypography
                                component="label"
                                variant="caption"
                                textColor="secondary"
                              >
                                {isShow ? "Show Password" : "hide Password"}
                              </SuiTypography>
                            </SuiBox>

                            {/* <SuiBox mt={2} mb={2} textAlign="center">
                              <h6
                                style={{
                                  fontSize: ".7em",
                                  color: "red",
                                  textAlign: "center",
                                  fontWeight: 400,
                                  transition: ".2s all",
                                }}
                              >
                                {error}
                              </h6>
                            </SuiBox> */}
                          </>
                        )}

                        <SuiBox py={2}>
                          <SuiButton
                            sx={{ mb: 1 }}
                            type="submit"
                            onClick={(event) => handleLogin(event)}
                            variant="gradient"
                            buttonColor="info"
                            fullWidth
                            circular
                            size={mobileView ? "small" : "medium"}
                          >
                            {buttonText}
                          </SuiButton>
                          <Separator />
                          <SuiButton
                            component={Link}
                            to="/sign-up"
                            sx={{ mt: 1 }}
                            variant="gradient"
                            buttonColor="dark"
                            fullWidth
                            circular
                            size={mobileView ? "small" : "medium"}
                          >
                            สมัครสมาชิก (Register)
                          </SuiButton>
                        </SuiBox>
                      </SuiBox>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </SuiBox>
            </SuiBox>
          </Grid>
          {!mobileView && <Grid item xs={12} lg={4} />}
        </Grid>
      </SuiBox>
    </PageLayout>
  );
}

export default Basic;
