/* eslint-disable */
import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import { CircularProgress, Grid } from "@mui/material";
// import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import TranferPopup from "components/TranferPopup/TranferPopup";
import { ResponseAlert } from "components/ResponseAlert";
import { GameLobbyURL, startUfa, startBetflix, startLottery } from "api/member";
import { EligibleTranfer } from "api/tranfer";
import breakpoints from "assets/theme/base/breakpoints";

// Custom styles for the MasterCard
import styles from "./styles";

import gamebetflix2 from "../../../../assets/png/gamebetflix2.png";
import playbtn from "../../../../assets/png/playbtn.png";
import tranferbtn from "../../../../assets/png/tranferbtn.png";
import huaybtn from "../../../../assets/png/huaybtn.png";
import startbtn from "../../../../assets/png/startbtn.png";
import bflogo from "../../../../assets/png/bflogo.png";
import lottologo from "../../../../assets/png/lottologo.png";
import ufalogo2 from "../../../../assets/png/ufalogo.png";
import huaydragonlogo from "../../../../assets/png/huaydragonlogo.png";
import ufagame from "../../../../assets/png/ufagame2.png";
import betflixgame from "../../../../assets/png/betflixgame2.png";
import oklotterygame from "../../../../assets/png/oklotterygame2.png";
import huaydragongame from "../../../../assets/png/huaydragongame2.png";
import AGENT_MAINTENANCE from "../../../../assets/png/AGENT_MAINTENANCE_TEXT.png";
import AG_MEMBER_LOCKED from "../../../../assets/png/AG_MEMBER_LOCKED.png";
import gearGIF from "../../../../assets/gif/gear.gif";
import attention from "../../../../assets/png/attention.png";
import gamebetflix from "../../../../assets/png/gamebetflix.png";
import gameufa from "../../../../assets/png/gameufa.png";
import gamelottery from "../../../../assets/png/gamelottery.png";
import AGENT_NOT_AVAILABLE_TEXT from "../../../../assets/png/AGENT_NOT_AVAILABLE_TEXT.png";
import SuiButton from "components/SuiButton";

const betflixLinks = [
  "https://www.083510.com",
  "https://www.083511.com",
  "https://www.083512.com",
  "https://www.083513.com",
  "https://www.083514.com",
  "https://www.083515.com",
];

function AgentCircle({ color, isLoading, agent, image, balance, agentBalance, onFetchBalance }) {
  const classes = styles({ color, agent, image });
  const [memberBalance, setMemberBalance] = useState(balance);
  const [memberAgBalance, setMemberAGBalance] = useState(agentBalance);
  const [isTranferPopup, setTranferPopup] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  // const [eligibleTranfer, setEligible] = useState(false);
  // const [isAutoPlay, setAutoPlay] = useState(false);
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  async function handleTranferPopup() {
    try {
      const response = await EligibleTranfer();
      console.log("handleTranferPopup-response", response);
      if (response?.data?.status === "success") {
        if (response?.data?.data?.eligible) {
          if (agent !== "lottery") {
            setTranferPopup(true);
          }
        } else {
          const sec = response?.data?.data?.wait;
          const wait = 5 - Number(sec);
          console.log("handleTranferPopup-wait", wait);
          alert(`คุณลูกค้าสามารถโยกเงินได้อีกครั้งในอีก ${wait} วินาที`);
        }
      }

      // if (isAutoPlay) {
      //   setAutoPlay(false);
      // }
    } catch (err) {
      ResponseAlert(err?.response);
      console.log("handleTranferPopup", err);
      console.log("handleTranferPopup", err.response);
    }
  }

  function randomLinkBetflix(links) {
    return links[Math.floor(Math.random() * links.length)];
  }

  async function getLobbyUrl() {
    const newWindow = window.open("about:blank", "_blank");
    if (memberAgBalance !== "AGENT_MAINTENANCE") {
      try {
        const gameLobby = await GameLobbyURL({ agent });
        if (gameLobby?.data?.status === "success") {
          // Swal.close();
          if (agent === "betflix") {
            const loginToken = await gameLobby?.data?.data?.betflix?.login_token;
            if (loginToken) {
              const bflink = randomLinkBetflix(betflixLinks);
              const betflixUrl = `${bflink}/login/apilogin/${loginToken}`;
              newWindow.location.href = betflixUrl;
            }
          }
          if (agent === "ufa") {
            const gameUrl = await gameLobby?.data?.data?.ufa?.gameUrl;
            if (gameUrl) {
              newWindow.location.href = gameUrl;
            }
          }
          if (agent === "lottery") {
            const loginToken = await gameLobby?.data?.data?.lottery?.login_token;
            if (loginToken) {
              const link = "https://www.ok-lottery.com";
              const betflixUrl = `${link}/login?apilogin=${loginToken}`;
              newWindow.location.href = betflixUrl;
            }
          }
          if (agent === "huaydragon") {
            const loginUrl = await gameLobby?.data?.data?.huay?.url;
            if (loginUrl) {
              newWindow.location.href = loginUrl;
            }
          }
        }
      } catch (err) {
        Swal.close();

        ResponseAlert(err?.response);
        console.log("getLobbyUrl-catch-error", err);
        console.log("getLobbyUrl-catch-error-response", err.response);
      }
    }
  }

  async function handleStartMember() {
    let startAgent = startUfa;
    if (agent === "betflix") {
      startAgent = startBetflix;
    }
    if (agent === "lottery") {
      startAgent = startLottery;
    }
    Swal.fire({
      title: "กำลังเปิดใช้งาน",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await startAgent();
      console.log("handleStartMember-response", response);
      Swal.close();
      if (response?.data?.status === "success") {
        onFetchBalance();
        return;
      }
      ResponseAlert(response);
    } catch (error) {
      Swal.close();
      ResponseAlert(error.response);
      console.log("handleStartMember-catch-error", error.response);
    }
  }

  // User has switched back to the tab
  const onFocus = () => {
    console.log("Tab is in focus");
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    console.log("Tab is blurred");
    setTranferPopup(false);
  };

  useEffect(() => {
    if (window.innerWidth < breakpoints.values.lg) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
    setMemberAGBalance(agentBalance);
    setMemberBalance(balance);
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [balance, agentBalance]);

  console.log("memberAgBalance", memberAgBalance);
  return (
    <SuiBox width="100%" height="100%">
      <SuiBox backgroundColor="rgba(0, 0, 0, 0.5)" borderRadius="lg" py={1}>
        {isTranferPopup && (
          <TranferPopup
            agent={agent}
            balance={memberBalance}
            agentBalance={memberAgBalance}
            onSetTranferPopup={() => handleTranferPopup()}
            onFetchBalance={() => onFetchBalance()}
            onClose={() => setTranferPopup(false)}
          />
        )}
        <SuiBox sx={{ cursor: "pointer" }}>
          {/* <SuiBox component="img" src={image} alt="image" width="100%" /> */}
          {agent === "ufa" && (
            <SuiBox display="flex" justifyContent="center" px={1}>
              <SuiBox
                component="img"
                src={ufagame}
                alt="ufa"
                width="100%"
                onClick={() => getLobbyUrl()}
              />
            </SuiBox>
          )}
          {agent === "betflix" && (
            <SuiBox display="flex" justifyContent="center" px={1}>
              <SuiBox
                component="img"
                src={betflixgame}
                alt="betflix"
                width="100%"
                onClick={() => getLobbyUrl()}
              />
            </SuiBox>
          )}
          {agent === "lottery" && (
            <SuiBox display="flex" justifyContent="center" px={1}>
              <SuiBox
                component="img"
                src={oklotterygame}
                alt="lottery"
                width="100%"
                onClick={() => getLobbyUrl()}
              />
            </SuiBox>
          )}
          {agent === "huaydragon" && (
            <SuiBox display="flex" justifyContent="center" px={1}>
              <SuiBox
                component="img"
                src={huaydragongame}
                alt="lottery"
                width="100%"
                onClick={() => getLobbyUrl()}
              />
            </SuiBox>
          )}
          <SuiBox textAlign="center">
            {agent === "huaydragon" || agent === "lottery" ? (
              <SuiTypography component="label" variant="body2" textColor="white">
                {agent === "huaydragon" ? "ไทย ลาว หุ้น ยี่กี" : "ลอตเตอรี่ออนไลน์ 80 บาท"}
              </SuiTypography>
            ) : (
              <SuiTypography component="label" variant="body2" textColor="white">
                {memberAgBalance && memberAgBalance !== "INVALID_MEMBER"
                  ? formatter.format(memberAgBalance).toString()
                  : "0.00"}
              </SuiTypography>
            )}
          </SuiBox>
        </SuiBox>

        {isLoading ? (
          <SuiBox display="flex" justifyContent="center" alignItems="center">
            <CircularProgress size="2rem" />
          </SuiBox>
        ) : (
          <>
            {memberAgBalance === "INVALID_MEMBER" ||
            memberAgBalance === "NOT_AVAILABLE" ||
            memberAgBalance === "AGENT_MAINTENANCE" ||
            memberAgBalance === "AG_MEMBER_LOCKED" ? (
              <SuiBox display="flex" justifyContent="center" alignItems="center" widht="100%">
                {memberAgBalance === "INVALID_MEMBER" && (
                  <SuiBox
                    onClick={() => handleStartMember()}
                    sx={{ cursor: "pointer" }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <SuiBox
                      component="img"
                      src={startbtn}
                      alt="tranferbtn"
                      width={mobileView ? 80 : 100}
                    />
                  </SuiBox>
                )}
                {memberAgBalance === "NOT_AVAILABLE" && (
                  <SuiBox display="flex" justifyContent="center" alignItems="center" mt={2}>
                    <Grid sx={{}} container justifyContent="center" alignItems="center">
                      <Grid item lg={12}>
                        <SuiBox display="flex" justifyContent="center">
                          <SuiBox component="img" src={gearGIF} alt="gearGIF" width="40%" />
                        </SuiBox>
                      </Grid>
                      <Grid item lg={12}>
                        <SuiBox display="flex" justifyContent="center">
                          <SuiBox
                            component="img"
                            src={AGENT_NOT_AVAILABLE_TEXT}
                            alt="AGENT_NOT_AVAILABLE"
                            width="80%"
                          />
                        </SuiBox>
                      </Grid>
                    </Grid>
                  </SuiBox>
                )}
                {memberAgBalance === "AGENT_MAINTENANCE" && (
                  <SuiBox display="flex" justifyContent="center" alignItems="center" mt={2}>
                    <Grid sx={{}} container justifyContent="center" alignItems="center">
                      <Grid item lg={12}>
                        <SuiBox display="flex" justifyContent="center">
                          <SuiBox component="img" src={gearGIF} alt="gearGIF" width="40%" />
                        </SuiBox>
                      </Grid>
                      <Grid item lg={12}>
                        <SuiBox display="flex" justifyContent="center">
                          <SuiBox
                            component="img"
                            src={AGENT_MAINTENANCE}
                            alt="AGENT_MAINTENANCE"
                            width="80%"
                          />
                        </SuiBox>
                      </Grid>
                    </Grid>
                  </SuiBox>
                )}
                {memberAgBalance === "AG_MEMBER_LOCKED" && (
                  <SuiBox display="flex" justifyContent="center" alignItems="center">
                    <Grid sx={{}} container justifyContent="center" alignItems="center">
                      <Grid item lg={12}>
                        <SuiBox display="flex" justifyContent="center">
                          <SuiBox
                            component="img"
                            src={AG_MEMBER_LOCKED}
                            alt="AG_MEMBER_LOCKED"
                            width="80%"
                          />
                        </SuiBox>
                      </Grid>
                      <Grid item lg={12}></Grid>
                    </Grid>
                  </SuiBox>
                )}
              </SuiBox>
            ) : (
              <SuiBox
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                {agent === "ufa" || agent === "betflix" ? (
                  <SuiBox
                    onClick={() => handleTranferPopup()}
                    sx={{ cursor: "pointer" }}
                    display="flex"
                    // justifyContent="center"
                    // alignItems="center"
                  >
                    {/* <SuiButton buttonColor="primary" circular size="small" variant="gradient">
                      โยกเงิน
                    </SuiButton> */}
                    <SuiBox
                      component="img"
                      src={tranferbtn}
                      alt="tranferbtn"
                      width={mobileView ? 80 : 100}
                    />
                  </SuiBox>
                ) : (
                  <SuiBox />
                )}
                {agent === "huaydragon" ? (
                  <SuiBox
                    onClick={() => getLobbyUrl()}
                    sx={{ cursor: "pointer" }}
                    display="flex"
                    // justifyContent="center"
                    // alignItems="center"
                  >
                    {/* <SuiButton buttonColor="primary" circular size="small" variant="gradient">
                      โยกเงิน
                    </SuiButton> */}
                    <SuiBox
                      component="img"
                      src={huaybtn}
                      alt="huaybtn"
                      width={mobileView ? 80 : 100}
                    />
                  </SuiBox>
                ) : (
                  <SuiBox />
                )}
              </SuiBox>
            )}
          </>
        )}
      </SuiBox>
    </SuiBox>
  );
}

// Setting default values for the props of MasterCard
AgentCircle.defaultProps = {
  color: "dark",
  isLoading: false,
  agent: "ufa",
  balance: "0.00",
  agentBalance: "0.00",
  onFetchBalance: () => {},
};

// Typechecking props for the MasterCard
AgentCircle.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  isLoading: PropTypes.bool,
  agent: PropTypes.string,
  image: PropTypes.string.isRequired,
  balance: PropTypes.string,
  agentBalance: PropTypes.string,
  onFetchBalance: PropTypes.func,
};

export default AgentCircle;
