/* eslint-disable */
import { useState } from "react";
import PropTypes from "prop-types";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import { verifyCurrency, formatter } from "utils/validations";
import { Card, Grid, Radio } from "@mui/material";
import SuiButton from "components/SuiButton";

const API_SERVER = "https://www.29agentxapi.com/";

// eslint-disable-next-line
function AmountInput({ amount, onSetAmount, agBanks, defaultBank, onSetAgBank, onNextStep }) {
  const [error, setError] = useState(undefined);
  const [value, setValue] = useState(undefined);
  const [isDisable, setBtnDisable] = useState(true);
  console.log("amount", amount);

  const handelSet = (v) => {
    console.log("AmountInput - handelSet - v", v);
    setValue(v);
    onSetAmount(v);
    setBtnDisable(false);
  };

  const handleSetAmount = (event) => {
    setError(undefined);
    const v = event.target.value;
    console.log("handleSetAmount-v", v);
    if (!verifyCurrency(v)) {
      setValue(v);
      setBtnDisable(true);
      setError("กรอกเฉพาะตัวเลข, จำนวนเต็ม, จำนวนทศนิยม 2 หลัก เท่านั้น เช่น 100, 100.50");
    } else {
      setValue(v);
      onSetAmount(v);
      setBtnDisable(false);
    }
  };

  return (
    <SuiBox>
      <SuiTypography component="label" variant="caption" textColor="dark">
        เลือกบัญชีสำหรับโอนเงิน
      </SuiTypography>
      <Grid container spacing={1}>
        {agBanks &&
          agBanks.map((b) => {
            let isSelected = false;
            if (defaultBank?.agent_bank_number === b?.agent_bank_number) {
              isSelected = true;
            }

            return (
              <Grid item xs={12}>
                <Card>
                  <SuiBox
                    display="flex"
                    p={1}
                    backgroundColor={isSelected ? "info" : "white"}
                    backgroundGradient
                    sx={{ cursor: "pointer" }}
                    onClick={() => onSetAgBank(b)}
                    alignItems="center"
                  >
                    <SuiBox display="flex" flexDirection="column" justifyContent="center">
                      <Radio
                        key={b?.agent_bank_number}
                        value={`${b?.bank_name}`}
                        color="primary"
                        checked={isSelected}
                      />
                    </SuiBox>
                    <SuiBox display="flex" flexDirection="column" justifyContent="center" pr={2}>
                      <SuiAvatar
                        src={`${API_SERVER}images/banks/${b?.bank_code}.png`}
                        alt={b.bank_name}
                        size="sm"
                        variant="rounded"
                      />
                    </SuiBox>
                    <SuiBox>
                      <SuiBox display="flex">
                        <SuiTypography
                          component="span"
                          variant="caption"
                          textColor={isSelected ? "white" : "dark"}
                        >
                          {b.bank_name}
                        </SuiTypography>
                      </SuiBox>
                    </SuiBox>
                  </SuiBox>
                </Card>
              </Grid>
            );
          })}
      </Grid>
      <SuiBox mt={1}>
        <SuiTypography component="label" variant="caption" textColor="dark">
          จำนวนเงินที่ต้องการฝาก
        </SuiTypography>
        <SuiBox>
          <SuiInput
            value={value}
            onChange={(event) => handleSetAmount(event)}
            placeholder="จำนวน"
            // type="number"
          />
        </SuiBox>

        <SuiBox>
          <SuiTypography component="label" variant="caption" textColor="error">
            {error}
          </SuiTypography>
        </SuiBox>
      </SuiBox>

      <SuiBox display="flex" mt={2}>
        <SuiBox
          flex={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => handelSet("100")}
        >
          <SuiTypography component="span" variant="body2" textColor="secondary">
            100 ฿
          </SuiTypography>
        </SuiBox>
        <SuiBox width="0.05rem" backgroundColor="rgba(0, 0, 0, 0.3)" />
        <SuiBox
          flex={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => handelSet("200")}
        >
          <SuiTypography component="span" variant="body2" textColor="secondary">
            200 ฿
          </SuiTypography>
        </SuiBox>
        <SuiBox width="0.05rem" backgroundColor="rgba(0, 0, 0, 0.3)" />
        <SuiBox
          flex={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => handelSet("500")}
        >
          <SuiTypography component="span" variant="body2" textColor="secondary">
            500 ฿
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox m={1} height="0.05rem" backgroundColor="rgba(0, 0, 0, 0.3)" />
      <SuiBox display="flex">
        <SuiBox
          flex={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => handelSet("1000")}
        >
          <SuiTypography component="span" variant="body2" textColor="secondary">
            1,000 ฿
          </SuiTypography>
        </SuiBox>
        <SuiBox width="0.05rem" backgroundColor="rgba(0, 0, 0, 0.3)" />
        <SuiBox
          flex={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => handelSet("2000")}
        >
          <SuiTypography component="span" variant="body2" textColor="secondary">
            2,000 ฿
          </SuiTypography>
        </SuiBox>
        <SuiBox width="0.05rem" backgroundColor="rgba(0, 0, 0, 0.3)" />
        <SuiBox
          flex={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5px",
            paddingBottom: "5px",
            cursor: "pointer",
          }}
          onClick={() => handelSet("5000")}
        >
          <SuiTypography component="span" variant="body2" textColor="secondary">
            5,000 ฿
          </SuiTypography>
        </SuiBox>
      </SuiBox>
      <SuiBox mt={5}>
        <SuiButton
          variant="outlined"
          buttonColor="success"
          size="small"
          circular
          disabled={isDisable}
          onClick={() => onNextStep()}
        >
          {`+ ฝากเงิน  ${value ? formatter.format(value).toString() : ""}`}
        </SuiButton>
      </SuiBox>
    </SuiBox>
  );
}

AmountInput.defaultProps = {
  amount: "",
  onSetAmount: () => {},
  agBanks: [],
  defaultBank: {},
  onSetAgBank: () => {},
};

AmountInput.propTypes = {
  amount: PropTypes.string,
  onSetAmount: PropTypes.func,
  agBanks: PropTypes.arrayOf(PropTypes.shape),
  defaultBank: PropTypes.shape,
  onSetAgBank: PropTypes.func,
};

export default AmountInput;
