import PageLayout from "examples/LayoutContainers/PageLayout";
import curved6 from "assets/images/curved-images/curved6.jpg";
import AppBar from "@mui/material/AppBar";
import appbarlogo from "assets/png/appbarlogo.png";
import SuiBox from "components/SuiBox";
import RegisterPage from "./register-page";

function Basic() {
  return (
    <PageLayout image={curved6}>
      <AppBar position="sticky" color="inherit" style={{ backgroundColor: "transparent" }}>
        <SuiBox
          display="flex"
          width="100vw"
          height="60px"
          justifyContent="center"
          alignItems="center"
        >
          <SuiBox
            display="flex"
            component="img"
            src={appbarlogo}
            justifyContent="center"
            alignItems="center"
            height="50px"
          />
        </SuiBox>
      </AppBar>
      <RegisterPage />
    </PageLayout>
  );
}

export default Basic;
